import TextFieldComponent from '../../../inputs/TextFieldComponent';
import { RegionDropdown, CountryDropdown } from "react-country-region-selector";
import ButtonComponent from '../../../inputs/ButtonCompnent';
import { REFFERAL_VALUES } from '../../../services/Methods/normalMethods';
import { Check, Close } from '@mui/icons-material';
import { useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const UserInfo = (props) => {
    const { fieldValue, userCanLogin, countryState, setCountryState, _validateRefferalFunction, webAliesError, stateError, section, countryCodes } = props;
    const [isChecked, setIsChecked] = useState(true);

    const InputField = (name, label, type) => {
        return (
            <div className='md-3-field'>
                <TextFieldComponent
                    name={name}
                    type={type ? type : 'text'}
                    label={label}
                    value={fieldValue?.values?.[name]}
                    error={!!(fieldValue?.touched?.[name] && fieldValue?.errors?.[name])}
                    onChange={fieldValue?.handleChange}
                    errorMessage={fieldValue?.errors?.[name]}
                />
                {<>{(name === "phone" && process.env.REACT_APP_OTP_ENABLE === 'true') ? <span>Note: phone number will be used to validate a code in the next step *</span> : ""} </>}

            </div>
        )
    };

    return (
        <div className='container' style={{ marginTop: "100px" }}>
            <div className='row-form'>
                {InputField('firstName', "First Name")}
                {InputField('lastName', "Last Name")}
            </div>
            <div className='row-form'>
                <div className='select hide-country'>
                    {countryCodes?.length > 0 && <CountryDropdown
                        value={countryState?.country}
                        // blacklist={section === "enrollment" ? ['NZ', 'CA'] : []}
                        priorityOptions={['US', 'CA']}
                        name="country"
                        valueType="short"
                        whitelist={section === "enrollment" ? _.filter(countryCodes, (row) => !(['CA', 'NZ']?.includes(row))) : countryCodes}
                        onChange={(val) => setCountryState((prv) => ({ ...prv, country: val, countryError: "" }))} />
                    }
                    {countryState?.countryError && <p className="error">{countryState?.countryError}</p>}
                </div>

                <div className="select">
                    <RegionDropdown
                        country={countryState?.country || "US"}
                        disabled={!countryState?.country}
                        value={countryState?.state}
                        name="state"
                        valueType="short"
                        defaultOptionLabel="State"
                        countryValueType="short"
                        onChange={(val) => {
                            setCountryState((prv) => ({ ...prv, state: val, stateError: "" }));
                        }}
                    />
                    {countryState?.stateError && <p className="error">{countryState?.stateError}</p>}
                </div>
            </div>
            <div className='row-form'>
                {InputField('address', "Address")}
                {InputField('address_2', "Address 2 (optional) ")}
            </div>
            <div className='row-form'>
                {InputField('city', "City")}
                {InputField('zipcode', "Zip Code")}
            </div>
            <div className='row-form'>
                {InputField('company', "Company Name")}
                <div className='md-3-field' style={{ display: "flex", }}>
                    <div className='' style={{ flex: '100%' }}>
                        <TextFieldComponent
                            name="refferal"
                            type={'text'}
                            disabled={!!(REFFERAL_VALUES?.webalies)}
                            label={"Referral"}
                            value={fieldValue?.values?.refferal}
                            onChange={fieldValue?.handleChange}
                            error={!!webAliesError?.isRefferalError}
                            errorMessage={webAliesError?.isRefferalError}
                        />
                    </div>
                    {!(REFFERAL_VALUES?.webalies) &&
                        <ButtonComponent classes="d-flex" onClick={() => _validateRefferalFunction(fieldValue)}>
                            {webAliesError?.isRefferalValid ? <Check /> : <Close />} Valid
                        </ButtonComponent>
                    }
                </div>
            </div>
            <div className='row-form'>
                {InputField('phone', "Phone Number", 'phone')}
                <div className='md-3-field'>
                    <TextFieldComponent
                        name="webAlias"
                        type={'text'}
                        label={"Web Alias"}
                        value={fieldValue?.values?.webAlias}
                        onChange={fieldValue?.handleChange}
                        error={!!webAliesError?.isWebAliesError || !!(fieldValue?.touched?.webAlias && fieldValue?.errors?.webAlias)}
                        errorMessage={webAliesError?.isWebAliesError || fieldValue?.errors?.webAlias}
                    />
                    <span style={{ fontSize: "13px", marginLeft: "7px" }}>Name your personal website</span>
                </div>
            </div>
            {!userCanLogin &&
                <div className='row-form'>
                    {InputField('password', "Password", 'password')}
                    {InputField('confirm_password', "Confirm Password", 'password')}
                </div>
            }
            <div className=''>
                <div className='checkbox'>
                    <input
                        type='checkbox'
                        id='SmsChecked'
                        checked={isChecked}
                        readOnly
                        onChange={() => setIsChecked(true)}
                    />
                    <label htmlFor='SmsChecked'>
                        <span className="checkbox-label-text">I agree to be contacted via email and SMS.</span>
                    </label>
                </div>
                <Link to="https://www.abeytunaturals.com/pdf/ShamanCompensationPlan.pdf" target='_blank' style={{
                    marginLeft: "20px",
                    color: '#000',
                    textDecoration: 'underline'

                }} >Compensation plan</Link>
            </div>
        </div>
    )
}

export default UserInfo
