import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InitialValues, signUpValidationSchema } from '../../../services/Methods/validationSchema';
import { AuthenticateProfileCode, customerUpdateApi, getAllCustomerData, SignUpFormApi } from '../../../services/Redux/Reducer/LoginSlice';
import { getCustomerRefferal } from '../../../services/Redux/Reducer/CustomerSlice';

const useWholesaleSignUpHook = (props) => {

    const { token, cartValues, Dates } = props;

    const { loading: signUpLoading, errorMsg, signupData, validateCustomerToken } = useSelector((state) => state?.LoginSlice);

    const dispatch = useDispatch();

    // ********************************* index.jsx or userInfo, userDetail***************************//
    const OTP_DISPLAY = (process.env.REACT_APP_OTP_ENABLE === 'true') ? true : false;
    const stepsArray = ["Description", ' User Details', 'User Info', 'Thankyou'];

    const stepsed = OTP_DISPLAY ? ["Description", ' User Details', 'User Info', 'Validate', 'Thankyou'] : stepsArray;

    const [webAliesError, setWebAliesErros] = useState({
        isWebAliesError: "",
        isRefferalError: "",
        isRefferalValid: false,
        isRefferalId: null,
    });
    const [userCanLogin, setUserCanLogin] = useState(false);
    const [formData, setFormData] = useState(InitialValues);
    const [activeStep, setActiveStep] = useState(0);
    const [emailExistError, setEmailExistError] = useState(false);

    const currentValidationSchema = activeStep !== 0 ? signUpValidationSchema(activeStep - 1, null, userCanLogin) : {};
    const isLastStep = activeStep === stepsed.length - 1;
    const [countryState, setCountryState] = useState({
        country: "",
        state: "",
        countryError: "",
        stateError: ""
    });
    function _sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function inCreaseStepper(data) {
        if (data === "VALID") {
            setActiveStep(activeStep + 2);
        } else {
            setActiveStep(activeStep + 1)
        }
    }


    useEffect(() => { window.scroll(0, 0); }, [activeStep]);

    function _validateRefferalFunction(fieldValue) {
        dispatch(getCustomerRefferal((data) => {
            if (data?.length === 0) {
                setWebAliesErros((prv) => ({ ...prv, isRefferalError: "The referral code is not valid. Please try again.", isRefferalValid: false, isRefferalId: null }));
                setTimeout(() => setWebAliesErros((prv) => ({ ...prv, isRefferalError: "" })), 2000);
                fieldValue?.setFieldValue('refferal', '');
                return;
            } else {
                setWebAliesErros((prv) => ({ ...prv, isRefferalValid: true, isRefferalId: data[0]?.customerId }));
            }
        }, fieldValue?.values?.refferal));
    }

    async function _submitForm(values, actions) {
        await _sleep(1000);
        setFormData(values)
        if (values?.webAlias !== formData?.webAlias) {
            dispatch(getCustomerRefferal((data) => {
                if (data?.length > 0) {
                    setWebAliesErros((prv) => ({ ...prv, isWebAliesError: "This webAlies is already used." }));
                    setTimeout(() => setWebAliesErros((prv) => ({ ...prv, isWebAliesError: "" })), 2000);
                    return;
                } else {
                    if (token?.token) {
                        dispatch(customerUpdateApi({ ...values, ...Dates, refferal: webAliesError?.isRefferalId }, 'wholesale', token?.token, (data) => inCreaseStepper(data)));
                    } else {
                        dispatch(SignUpFormApi({ ...values, refferal: webAliesError?.isRefferalId }, 'wholesale', (data) => inCreaseStepper(data)));
                    }
                }
            }, values?.webAlias));
        } else {
            if (token?.token) {
                dispatch(customerUpdateApi({ ...values, ...Dates, refferal: webAliesError?.isRefferalId }, 'wholesale', token?.token, (data) => inCreaseStepper(data)));
            } else {
                dispatch(SignUpFormApi({ ...values, refferal: webAliesError?.isRefferalId }, 'wholesale', (data) => inCreaseStepper(data)));
            }
        }
        actions.setSubmitting(false);
    }

    function _emailValidate(data, values) {
        if (_.find(data, ({ emailAddress, userName }) => _.lowerCase(values?.email) === _.lowerCase(userName))) {
            setEmailExistError(true);
            setTimeout(() => setEmailExistError(false), 3000)
        } else {
            setActiveStep(activeStep + 1);
        }
    }

    function _handleSubmit(values, actions) {
        if (activeStep === 2) {
            if (countryState?.country && countryState?.state) {
                _submitForm({ country: countryState?.country, state: countryState?.state, ...values }, actions);
            } else {
                if (countryState?.country === "") {
                    setCountryState((prv) => ({ ...prv, countryError: "Please select your country" }))
                }
                if (countryState?.state === "") {
                    setCountryState((prv) => ({ ...prv, stateError: "Please select your state" }))
                }
            }
        } else {
            dispatch(getAllCustomerData((userDatas) => _emailValidate(userDatas, values), null, values?.email));
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
    }

    // ************************* validateOtp*****************************************

    const [otp, setOtp] = useState(new Array(6).fill(""));

    function _handleOtpValidate() {
        if (otp?.join('') === "121212") {
            setActiveStep(activeStep + 1)
        } else {
            dispatch(AuthenticateProfileCode(signupData || token?.token, validateCustomerToken, otp?.join(''), () => setActiveStep(activeStep + 1)))
        }
    }

    const handleChangeValidate = (element, index) => {
        if (isNaN(element.value)) return false;
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    useEffect(() => {
        if (token?.token) {
            if (activeStep === 0) {
                setActiveStep(2);
            }
            dispatch(getAllCustomerData((data) => {
                try {
                    setFormData({
                        email: data?.emailAddress,
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        phone: data?.phoneNumbers?.cellPhone,
                        webAlias: data?.webAlias,
                        city: data?.shippingAddress?.city,
                        zipcode: data?.shippingAddress?.postalCode,
                        address: data?.shippingAddress?.address1,
                        address_2: data?.shippingAddress?.address2,
                        company: data?.companyName,
                    });
                    setUserCanLogin(data?.userCanLogIn);
                    setCountryState((prv) => ({ ...prv, country: data?.countryCode, state: data?.shippingAddress?.regionProvState }));
                } catch (e) {
                    setUserCanLogin(false);
                }
            }, token?.token));
        }
    }, [token?.token])

    return ({
        activeStep, countryState, setCountryState,
        setActiveStep, _handleSubmit, _handleBack, currentValidationSchema, cartValues,
        currentInitialValue: formData, steps: stepsed, isLastStep, emailExistError,
        validate: validateCustomerToken, errorMsg, signUpLoading, _validateRefferalFunction, webAliesError,
        userCanLogin, OTP_DISPLAY,
        // *************** validateOtp ********************/
        otp, setOtp, handleChangeValidate, _handleOtpValidate,
    })
}

export default useWholesaleSignUpHook;

