import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import withRouter from "../services/HOC/withRouterHOC";
import ButtonComponent from "../inputs/ButtonCompnent";
import { Divider, Drawer } from "@mui/material";
import MiniCartStyleCss from "./components/MiniCart.style";
import { FloatValues, storeAssigned, _deleteProductFromCart, _productPrice } from "../services/Methods/normalMethods";
import _ from "lodash";
import SelectComponent from "../inputs/SelectComponent";
import PlusSvg from './assets/cross-bg.png';
import useMiniCartHook from "./components/Minicart.hook";

function USStore(props) {
    const { cartValues, alluserData, setCartValues, openMiniCart, setOpenMiniCart, token, countryCodes, Dates } = useOutletContext();
    const {
        miniSubtotal, totalItemsInMini, miniCartData, SHIPPING_SECTION, navigate, productsDetail,
        toggleDrawer, _IncreaseDecreaseFunction, _changeAutoshipFunction, isProductLoading
    } = useMiniCartHook({ setOpenMiniCart, cartValues, setCartValues, ...props });

    return (
        <>
            <div className="us_store">
                <Outlet context={{
                    cartValues, navigate, setCartValues, token, alluserData, Dates, countryCodes,
                    store: "US", setOpenMiniCart, ProductsDetail: productsDetail, isProductLoading
                }} />
            </div>
            <Drawer
                anchor={'right'}
                open={openMiniCart}
                onClose={toggleDrawer(false)}
                classes={{ paper: "flayout_cart" }}
            >
                <MiniCartStyleCss>
                    <div className="cart-popup-main">
                        <div className="heading_section d-flex">
                            <h1>Your Cart</h1>
                            <div className="side-cart-cross">
                                <img src={PlusSvg} alt="minCart" style={{ cursor: "pointer" }} onClick={() => setOpenMiniCart(false)} />
                            </div>
                        </div>
                    </div>
                    {(cartValues?.length > 0) ?
                        <>
                            <div className="products_container">
                                {_.map(miniCartData, (row) => (
                                    <div key={row?.id}>
                                        <div className="products_listing" >
                                            <div className="product_image">
                                                <img src={row?.image} alt="img" />
                                            </div>
                                            <div className="product_details">
                                                <div className="product_name">
                                                    <h3>{row?.productName}</h3>
                                                </div>
                                                <div className="quality-box">
                                                    <div className="input-group">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="quantity-left-minus btn btn-danger btn-number"
                                                                onClick={() => _IncreaseDecreaseFunction('sub', row?.id)}>
                                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                                            </button>
                                                        </span>
                                                        <input type="text" id="quantity" name="quantity" className="form-control input-number" value={row?.quantity} min="1" max="100" onChange={(e) => console.log(e)} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="quantity-right-plus btn btn-success btn-number"
                                                                onClick={() => _IncreaseDecreaseFunction('add', row?.id)}>
                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="delete_price">
                                                <div className="deleteIcon" onClick={() => _deleteProductFromCart(+row?.id, setCartValues)}>
                                                    <img src={require('./assets/deleteIcon2.png')} alt="delterIcon" />
                                                </div>
                                                <div className="price">${parseFloat(_productPrice(row?.prices, (row?.autoship !== "oneTime" ? 'autoship' : row?.autoship), row?.isEligibleForAutoOrder)).toFixed(2)}</div>
                                            </div>
                                        </div>
                                        <div className="shipping_dropdown">
                                            {(row?.isEligibleForAutoOrder) &&
                                                (row?.autoship !== "now") && <SelectComponent name="birth_month" options={SHIPPING_SECTION} label="" value={row?.autoship} onChange={(e) => _changeAutoshipFunction(+row?.id, e.target.value)} />
                                            }
                                        </div>

                                    </div>
                                ))}

                            </div>
                            <Divider />
                            <div className="frequantly_brought">

                            </div>
                            <Divider />
                            <div className="footer_section_mincart">
                                <div className="calculation_section">
                                    <div className="subtotal_section">
                                        <p>Subtotal ({totalItemsInMini ? totalItemsInMini : 0} Items)</p><p>${FloatValues(miniSubtotal)}</p>
                                    </div>
                                    <ButtonComponent type="button" onClick={() => {
                                        setOpenMiniCart(false);
                                        window.location.assign(`/${storeAssigned()}/cart`)
                                        // navigate(`/${storeAssigned()}/cart`);
                                    }}>View Cart</ButtonComponent>
                                </div>
                                <Divider />
                            </div>
                        </>
                        :
                        <p style={{ textAlign: "center" }}>Cart is Empty</p>
                    }
                </MiniCartStyleCss>
            </Drawer>
        </>
    );
}

export default withRouter(USStore);
