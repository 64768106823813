import styled from "styled-components";

const AllProductStyleCss = styled.div`
.Allproduct_section {
    .allProduct_banner-section {
        /* background: ${`url(${require('../assets/web-hero.jpg')})`};  */
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        /* height: 1203px; */
        position: relative;

        figure {
            margin: 0;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .container {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%)
        }
        .banner-text {
            h1 {
                color:#fff;
                font-size: 60px;
                padding: 196px 0 0 50px;
                text-shadow: 0px 4px 20px #000000CC;
                font-weight: bold;
                font-family:Playfair Display;
                @media(max-width:767px){
                    font-size:30px;
                    padding: 150px 0 0 0;
                }
                @media(max-width:568px){
                    font-size:26px;
                    padding: 100px 0 0 0;
                }
            }
        }
    }
    .main_Allproduct_container { 
            padding: 70px 0 0;

            .main_Allproduct_note {
                background: #f8d8dd;
                padding: 20px 25px;
                margin-bottom: 30px;

                .note-msg {
                    font-size: 20px;
                }

                p {
                    font-size: 14px;
                    padding: 0;
                    margin-bottom: 0;
                    line-height: 1.2;
                    
                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }

                    i {
                        margin-right: 7px;
                        font-size: 16px;
                        font-weight: 700;
                        margin-left: 10px;
                    }

                    a {
                        font-size: inherit;
                        line-height: inherit;
                        text-decoration: underline;
                        text-underline-offset: 3px;
                        border: none;
                        
                        &:hover {
                            text-decoration: none;
                            border: none;
                        }
                    }
                }
            }

            h2 {
                color: #2A533F;
            }

            p{
                color: #555555;
                font-size: 30px;
                line-height: 36px;
                padding: 5px 0 0;
                font-family: 'Helvetica';
            
                @media(max-width:568px){
                    font-size:16px;
                }
                
                &:first-child {
                    margin-bottom: 0px;
                }
                a {
                    color: #2A533F;
                    cursor: pointer;
                    &:hover {
                        border-bottom: 1px solid #2A533F;
                    }
                }
            }
           
            .toggle_section {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;
                .toggle-button {
                    padding: 8px 20px;
                    border: 1px solid #2a533f;
                    color: #000;
                    border-radius: 30px;
                    width: 100%;
                    max-width: 130px;
                    text-align: center;
                    &.active {
                        background: #2a533f;
                        color: #fff;
                    }

                    &:hover {
                        background: #2a533f;
                        color: #fff;
                    }
                }
            }
            .product_listing {
                margin: 80px 0;
                display: flex;
                gap: 70px 24px;
                flex-wrap: wrap;
                justify-content: center;
                @media(max-width:568px){
                    margin: 30px 0;
                    gap: 45px 24px;
                }
                .product_section {
                    box-shadow: rgb(0 0 0 / 4%) 0px 20px 70px;;
                    border-radius: 10px;
                    max-width: 318px;
                    flex: 0 0 318px;
                    overflow: hidden;
                    text-align: center;
                    position: relative;
                     height: 550px;
                     @media(max-width:568px){
                        max-width: 100%;
                        flex: 0 0 100%;
                        // height: 450px;
                        height: auto;
                        padding:34px 0;
                    }
                
                    .product_image {
                    /* background: #55555566; */
                    height: 305px;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;

                    @media(max-width:568px){
                        height: 230px;
                    }
                    img{
                        width: 100%;
                         max-width: 300px;
                         height: 100%;
                         object-fit: contain;
                    }
                    }
                h3 {
                    color: #2A533F;
                    font-size: 28px;
                    line-height: 31px;
                    font-family: "Playfair Display";
                    font-weight: bold;
                    padding: 20px 14px 5px;
                    height: 120px;
                    margin-bottom: 0;
                    @media(max-width:568px){
                        font-size: 18px;
                        margin: 10px 0;

                    }
                }
                p{
                    padding-top: 0;
                    font-size: 14px;
                    line-height: 28px;
                    border: thin solid rgb(42, 83, 63);
                    margin: 0px 70px 5px;
                    border-radius: 16px;

                    @media only screen and (max-width: 568px) {
                        max-width: 200px;
                        margin: 0 auto 20px;
                    }
                }
                   .price  {
                     

                        h4{
                            color: #39332E;
                            font-size: 24px;
                            font-weight: bold;
                            font-family: 'Helvetica Neue LT Std';
                            span.available {
                                font-size: 20px;
                                font-weight: 300;
                                color: #39332E;
                            }
                        }
                    }
                   .select_option{
                        position: absolute;
                        bottom: 0;
                        margin: 0 auto;
                        left: 0;
                        right: 0;
                        
                        h4 {
                            margin: 14px 0 6px;
                            line-height: 20px;
                        }
                        @media only screen and (max-width: 568px) {
                            position: static;
                        }
                        & a {
                                background: #000;
                                margin: 0 auto 15px;
                                background: #2A533F 0% 0% no-repeat padding-box;
                                border-radius: 28px;
                                height: 35px;
                                max-width: 163px;
                                width: 100%;
                                color: #F4F0EB;
                                font-size: 18px;
                                font-weight: bold;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: 2px solid transparent;
                                &:hover {
                                    background: transparent;
                                    color:#2A533F;
                                    border: 2px solid #2A533F;
                                }

                                @media only screen and (max-width: 568px) {
                                    margin: 0px auto;
                                }
                            }
                    }

                    .cr {
                        width: 200px;
                        position: absolute;
                        text-align: center;
                        color: #f0f0f0;
                        font-weight: 600;
                        font-size: 18px;
                        padding: 10px;
                        line-height: 20px;
                        text-align: center;
                    }

                    /* .cr-sticky {
                        position: fixed;
                    } */
                    .cr-top    { 
                        top: 17px;
                        right: -61px; 
                    }
                    .cr-top.cr-right {
                        transform: rotate(45deg);
                    }
                    .cr-red { background-color: #e43; }
                }
            }
            .allProductText p {
                font-size: 25px;
                margin-bottom: 10px;
            }
        }
        .main_pagination_section_class ul {
             background: none !important;
         
         li.previous.disabled {
                background: transparent !important;
            }
            li.next{
                background: transparent !important;
                
            }
        }
        .order_pagination ul li.previous a {
            background: transparent !important;
        }
        .order_pagination ul li.previous a {
            background: transparent !important;
        }
        .order_pagination ul li.next.disabled a ,  .order_pagination ul li.next a {
            background: transparent !important;
        }
       .main_pagination_section_class .order_pagination ul.pagination li.active a {
            color: #2A533F !important;
            font-size:30px !important;
            padding: 0;
        }
        .main_pagination_section_class li a {
            color: #555555 !important;
            font-size:25px;
        }
        .main_pagination_section_class ul li a svg {
    font-size: 40px;
    position: relative;
top: -2px;
}
.main_pagination_section_class ul li {
	padding: 0px 8px;
	width: auto !important;
	height: auto !important;
}
 .main_pagination_section_class .order_pagination ul.pagination li a {
	padding: 5px 0px !important;
}
 .main_pagination_section_class .order_pagination ul.pagination li.active a {
	color: #2a533f !important;
}
.main_pagination_section_class ul {
    padding: 0 0 100px !important;
    display: flex;
    align-items: baseline;
}
}
@media(max-width:1800px){
.Allproduct_section .allProduct_banner-section {
    /* background-position: top -200px center; */
}
}
@media(max-width:1600px){
}
@media(max-width:1520px){
 /* .Allproduct_section .allProduct_banner-section {
    height: 850px;
} */
}
@media(max-width:1500px){
.Allproduct_section .allProduct_banner-section {
    /* height: 760px; */
    background-size: cover;
    background-position: top -158px center;
}
}
@media(max-width:1439px){
.Allproduct_section .allProduct_banner-section {
    /* height:815px; */
}
}
@media(max-width:1380px){
.Allproduct_section .main_Allproduct_container {
    padding: 0;
}
}
@media(max-width:1300px){
/* .Allproduct_section .allProduct_banner-section {
    height: 760px;
} */
}

@media(max-width:1240px){
.Allproduct_section .allProduct_banner-section {
    background-position: center;
}
.Allproduct_section .main_Allproduct_container {
    padding: 25px 0 0;
}
}
@media(max-width:991px){
/* .Allproduct_section .allProduct_banner-section {
    height: 640px;
} */
}
@media(max-width:767px){
/* .Allproduct_section .allProduct_banner-section {
    height: 500px;
} */
}
@media(max-width:568px){
/* .Allproduct_section .allProduct_banner-section {
    height: 375px;
} */

}
`;

export default AllProductStyleCss;