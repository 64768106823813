import React, { useEffect, useState } from 'react'
import { ViewCartStyle } from './ViewCart.style'
import ProductImage from '../assets/prod1.png';
import _ from 'lodash';
import { FloatValues, storeAssigned, _deleteProductFromCart, _productPrice } from '../../../services/Methods/normalMethods';
import classNames from 'classnames';
import { Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';

const ViewCart = (props) => {
    const { selectedPrice, setSelectedPrice, viewCartData, qtyValues, totalAmount, _IncreaseDecreaseFunction, setCartValues, _changeAutoshipFunction } = props;

    const navigate = useNavigate();
    const [loginToken, setLoginToken] = useState();

    useEffect(() => {
        const TokenData = localStorage.getItem("Token");
        setLoginToken(TokenData)
    }, [])


    return (
        <ViewCartStyle>
            <div className='view-cart'>
                <div className='container'>
                    <div className="row">
                        <div className='col-md-12'>
                            <div className="view-cart-part">
                                <h2 className='cart-heading'>Your Cart</h2>
                                {(viewCartData?.length > 0)
                                    ?
                                    <div className='table-data'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>PRODUCT</th>
                                                    <th>PRICE</th>
                                                    <th>QUANTITY</th>
                                                    <th>SUBTOTAL</th>
                                                    <th>ACTIONS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    viewCartData?.map((items) => {
                                                        return (
                                                            <tr key={items?.id}>
                                                                <td>
                                                                    <div className='product-image'>
                                                                        <img src={items?.image} height="85" />
                                                                        <span>{items?.productName}</span>
                                                                    </div>
                                                                </td>
                                                                <td>

                                                                    <div className='price' >
                                                                        {items?.isEligibleForAutoOrder
                                                                            ?
                                                                            <div className='most-prices'>
                                                                                <div className='price-bar'>
                                                                                    <h6>MOST POPULAR</h6>
                                                                                </div>

                                                                                <div className='one-time'>
                                                                                    {_.map([
                                                                                        { title: "One time", section: "oneTime", price: _productPrice(items?.prices, 'oneTime', items?.isEligibleForAutoOrder), classes: "first-time common_css" },
                                                                                        { title: '30 DAYS', section: "30Days", price: _productPrice(items?.prices, 'autoship', items?.isEligibleForAutoOrder), classes: "second-time common_css" },
                                                                                        { title: '60 DAYS', section: "60Days", price: _productPrice(items?.prices, 'autoship', items?.isEligibleForAutoOrder), classes: "third-time common_css" },
                                                                                    ], ({ title, section, price, classes }, index) => (

                                                                                        <div key={section}
                                                                                            className={classNames(null, classes, { 'selected': (_.find(selectedPrice, { id: items?.id })?.value === section) })}
                                                                                            onClick={() => _changeAutoshipFunction(items?.id, section)}>
                                                                                            {index !== 0 && <span className="every">EVERY</span>}
                                                                                            <p>{title}</p>
                                                                                            <strong>${FloatValues(price)}</strong>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className='sub-total' style={{ textAlign: "center" }}>
                                                                                ${_productPrice(items?.prices, 'oneTime', items?.isEligibleForAutoOrder)}
                                                                                <h3 style={{ color: "#000" }}>One time</h3>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="input-group">
                                                                        <span className="input-group-btn">
                                                                            <button type="button" className="quantity-left-minus btn btn-danger btn-number" onClick={() => _IncreaseDecreaseFunction('sub', items?.id)}>

                                                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                                                            </button>
                                                                        </span>
                                                                        <input type="text" id="quantity" name="quantity" className="form-control input-number" value={items?.quantity} min="1" max="100" onChange={(e) => console.log(e)} />
                                                                        <span className="input-group-btn">
                                                                            <button type="button" className="quantity-right-plus btn btn-success btn-number" onClick={() => _IncreaseDecreaseFunction('add', items?.id)}>
                                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='sub-total'>
                                                                        ${FloatValues(_.find(qtyValues, ({ id }) => id === items?.id)?.subtotalPriceQTY)}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='delete-product' style={{ cursor: "pointer" }}>
                                                                        <span className='delete-icon' onClick={() => { _deleteProductFromCart(+items?.id, setCartValues); }}> <Delete /></span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='horizontal-line0'></div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>

                                        {/* <div className='coupon'>
                                        <input
                                            type="text"
                                            name="coupon_id"
                                            placeholder="Coupon Code"
                                        />
                                        <button type="button">Apply
                                        </button>
                                    </div> */}
                                        <div className='price-calulate'>
                                            <div className='subtotal-item'>Subtotal: ${FloatValues(totalAmount)}</div>
                                            <div className='subtotal-item'>Tax: $0.00</div>
                                            <div className='subtotal-item'>TOTAL: ${FloatValues(totalAmount)}</div>

                                            {!loginToken ?
                                                <div className='checkout-btn'>
                                                    <button type='button' onClick={() => navigate(`/${storeAssigned()}/retail_signup`)}>Checkout</button>
                                                </div>
                                                :
                                                <div className='checkout-btn'>
                                                    <button type='button' onClick={() => navigate(`/${storeAssigned()}/checkout`)}>Checkout</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <p>Cart is empty</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ViewCartStyle>

    )
}

export default ViewCart