import React from 'react';

const WholeSaleDiscription = () => {
    return (
        <div className='discription_section'>
            <h2> Wholesale Accounts are issued by approval only.</h2>
            <ul style={{ listStyleType: "square" }}>
                <li>You must schedule a 15-minute "introduction to our products" meeting with one of our representatives.</li>
                <li>You must have a store window, medical/wellness clinic, or a personal business for which you intend to sell retail to customers.</li>
                <li>Wholesale minimum order is 25 units, and you can mix & match products.</li>
                <li>All wholesale pricing is visible in our wholesale portal.</li>
                <li>You may sign up as a wholesaler AND an affiliate so you never lose a sale.</li>
            </ul>
        </div>
    )
}

export default WholeSaleDiscription;