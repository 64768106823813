import { DialogActions } from '@mui/material';
import _ from 'lodash';
import React from 'react'
import ReactCountryFlag from "react-country-flag";
import DialogComponent from '../../../../inputs/DailogComponent';
import localStorageCall from '../../../../services/Methods/localstorage.hook';
import { ButtonCssClass } from '../../../../services/Methods/normalMethods';

const CountryFlagDropdown = ({
    countyObjects, showCountries, selectedStore, setSelectedStore, setShowCountries
}) => {
    return (
        <>
            <ul className={showCountries ? "country-drop open-country" : "country-drop"}>
                {_.map([
                    ..._.reverse(_.filter(countyObjects, (row) => _.includes(['US', 'CA'], row?.code))),
                    ..._.reject(countyObjects, (row) => _.includes(['US', 'CA'], row?.code))],
                    (row) => (
                        <li key={row?.code} className="" onClick={() => {
                            setSelectedStore((prv) => ({
                                ...prv, newCode: _.lowerCase(row?.code)
                            }));
                            setShowCountries(!showCountries);
                        }}>
                            <button className="dropdown-item !py-1">
                                <ReactCountryFlag className="" countryCode={row?.code} style={{ fontSize: '1em', marginRight: '4px', lineHeight: '2em' }} svg />
                                {row?.name}
                            </button>
                        </li>
                    ))}
            </ul>
            <DialogComponent
                opend={!!(selectedStore?.defaultCode !== selectedStore?.newCode)}
                handleClose={() => setSelectedStore((prv) => ({ ...prv, newCode: prv?.defaultCode }))}
                // titleBanner={ }
                title='Change Country'
                classFor={"country_askingPopup"}
            >
                <div>
                    <p>Are you sure you want to change country? Your cart will be emptied and you will be sent back to the homepage.</p>
                </div>
                <DialogActions className='action_button justify-center' >
                    <button onClick={() => setSelectedStore((prv) => ({ ...prv, newCode: prv?.defaultCode }))}>Cancel</button>
                    <button onClick={() => {
                        localStorageCall().removeItem('cartData');
                        localStorageCall().setItem('store', selectedStore?.newCode);
                        setSelectedStore((prv) => ({ ...prv, defaultCode: prv?.newCode }))
                        window.location.assign(`/${selectedStore?.newCode}`)
                    }}>Ok</button>
                </DialogActions>
            </DialogComponent>
        </>
    )
}

export default CountryFlagDropdown;