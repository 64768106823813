import React from 'react'
import { Box, Stepper, Step, StepLabel, Grid } from '@mui/material';
import { WholeSaleProgramStyle } from './components/WholesaleProgram.style';
import { Form, Formik } from 'formik';
import ButtonComponent from '../../inputs/ButtonCompnent';
import { useOutletContext } from 'react-router-dom';
import _ from 'lodash';
import LoaderComponent from '../../Layout/components/Footer/components/Loader';
import { REFFERAL_VALUES } from '../../services/Methods/normalMethods';
import UserInfo from '../EnrollmentSignup/components/UserInfo';
import EmailVerify from '../EnrollmentSignup/components/EmailVerify';
import OneTimePass from '../EnrollmentSignup/components/OneTimePass';
import WholeSaleDiscription from './components/WholeSaleDiscription';
import WholeSaleThankyou from './components/WholeSaleThankyou';
import useWholesaleSignUpHook from './components/WholesaleProgram.hook';

const formContent = (step, { payload, fieldValue, userCanLogin, countryCodes }) => {
    const {
        setActiveStep, _validateRefferalFunction, webAliesError, setCountryState, countryState, emailExistError,
        setOtp, handleChangeValidate, otp
    } = payload;
    if (process.env.REACT_APP_OTP_ENABLE === "true") {
        switch (step) {
            case 0:
                return <WholeSaleDiscription />;
            case 1:
                return <EmailVerify {...{ emailExistError, fieldValue }} />;
            case 2:
                return <UserInfo {...{ countryCodes, userCanLogin, _validateRefferalFunction, webAliesError, countryState, setCountryState, fieldValue, section: "wholesale" }} />;
            case 3:
                return <OneTimePass {...{ setActiveStep, otp, setOtp, handleChangeValidate }} />
            case 4:
                return <WholeSaleThankyou />
            default:
                return <div className='not-found'>404: Not Found</div>
        }
    }
    else {
        switch (step) {
            case 0:
                return <WholeSaleDiscription />;
            case 1:
                return <EmailVerify {...{ emailExistError, fieldValue }} />;
            case 2:
                return <UserInfo {...{ countryCodes, userCanLogin, _validateRefferalFunction, webAliesError, countryState, setCountryState, fieldValue, section: "wholesale" }} />;

            case 3:
                return <WholeSaleThankyou />
            default:
                return <div className='not-found'>404: Not Found</div>
        }
    }

};

const WholesaleProgramSignup = (props) => {
    const { cartValues, setCartValues, ProductsDetail, token, countryCodes, Dates } = useOutletContext();

    const {
        steps, isLastStep, activeStep, setActiveStep, _handleSubmit, _handleBack, currentValidationSchema,
        currentInitialValue, _handleOtpValidate, signUpLoading, errorMsg, userCanLogin, OTP_DISPLAY, ...others
    } = useWholesaleSignUpHook({ token, Dates, cartValues, setCartValues, ProductsDetail, ...props });
    console.log("isLastStep", isLastStep)

    return (
        <WholeSaleProgramStyle>
            {(signUpLoading) && <LoaderComponent />}
            <div className={activeStep === 3 ? 'linear-form checkout_main_div_add' : 'linear-form'}>
                <Box sx={{ maxWidth: '600px', padding: 2 }}>
                    <Stepper activeStep={activeStep} alternativeLabel orientation="horizontal">
                        {steps?.map((label, index) => (
                            <Step key={index}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box component="div">
                        <Grid item xs={12} sx={{ padding: '20px' }}>
                            <Formik
                                enableReinitialize
                                initialValues={{ ...currentInitialValue, refferal: REFFERAL_VALUES?.webalies }}
                                validationSchema={currentValidationSchema}
                                onSubmit={(values, action) => {
                                    _handleSubmit(values, action)
                                }}
                            >
                                {({ isSubmitting, ...fieldValue }) => (
                                    <>
                                        {(activeStep < 3)
                                            ?
                                            <>
                                                <Form>
                                                    {formContent(activeStep, {
                                                        ...{ currentInitialValue, setActiveStep, payload: others, fieldValue, countryCodes, userCanLogin }
                                                    })}
                                                    <div className="product-button-back">
                                                        {(activeStep !== 0 && !token?.token) && (
                                                            <ButtonComponent onClick={_handleBack} className="">Back</ButtonComponent>
                                                        )}
                                                        {(activeStep === 0)
                                                            ?
                                                            <ButtonComponent type="button" classes="wholesale-pro" onClick={() => {
                                                                setActiveStep(activeStep + 1);
                                                            }}>Submit Your Intake Form</ButtonComponent>
                                                            :
                                                            <ButtonComponent type="submit" classes="">Next</ButtonComponent>
                                                        }
                                                    </div>
                                                </Form>
                                                {errorMsg && <p className='' style={{ color: "red" }}>{errorMsg}</p>}
                                            </>
                                            :
                                            <div className='products_section'>
                                                {formContent(activeStep, {
                                                    ...{ currentInitialValue, setActiveStep, payload: others, fieldValue, countryCodes, userCanLogin }
                                                })}
                                                <div className="product-button-back">
                                                    {_.includes(["Autoship", "Summary"], steps[activeStep]) && (
                                                        <ButtonComponent onClick={_handleBack} className="">Back</ButtonComponent>
                                                    )}
                                                    {
                                                        (!isLastStep) &&
                                                        (<ButtonComponent classes="" onClick={() => {
                                                            if (others?.validate === "VALID" && steps[activeStep] === "Validate") {
                                                                _handleOtpValidate();
                                                            } else {

                                                                setActiveStep(activeStep + 1);
                                                            }
                                                        }}>Next</ButtonComponent>)
                                                    }
                                                </div>
                                                {errorMsg && <p className='' style={{ color: "red" }}>{errorMsg}</p>}
                                            </div>
                                        }
                                    </>
                                )}
                            </Formik>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </WholeSaleProgramStyle >
    )
}

export default WholesaleProgramSignup;