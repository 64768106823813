import _ from 'lodash';
import React from 'react';
import ButtonComponent from '../../../inputs/ButtonCompnent';

const WholeSaleThankyou = () => {
    return (
        <div className='thankyou_wholesale'>
            <h3> Thank you for submitting your intake form.</h3>
            <p>Please choose a 15-minute product overview call below:</p>

            <div className='button_sections'>
                {_.map([
                    { name: "Beverly", link: "https://calendly.com/beverly-abeytu", rank: "Corporate Liaison", image: require('../assets/beverly.jpg') },
                    { name: "Maria", link: "https://calendly.com/maria-abeytu", rank: "CEO", image: require('../assets/maria.jpg') },
                    // { name: "Emma", link: "https://calendly.com/emma-tiq", rank: "Chief Medical Officer", image: require('../assets/emma.jpg') }
                ], ({ name, image, link, rank }) => (
                    <div className="member_detail" key={name}>
                        <div className="executive_image">
                            <img src={image} alt="ExecutiveImage" />
                        </div>
                        <div className="executive_bio">
                            <h3>{name}</h3>
                            <div className="rank_discription">
                                <p>{rank}</p>
                                <ButtonComponent onClick={() => {
                                    window.open(link, "_blank");
                                }}>Call with {name}</ButtonComponent>

                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <p>We also offer staff training, please inquire.</p>
        </div>
    )
}

export default WholeSaleThankyou;