import styled from "styled-components";

const MiniCartStyleCss = styled.div`


.heading_section {
    justify-content: center;
    background-color: #fff;
    position: relative;

    h1 {
        font: normal normal bold 46px/70px Playfair Display;
        letter-spacing: 0px;
        color: #2A533F;
        /* padding: 20px 0; */
        padding: 0;
        margin: 0;
    }
    p {
        margin: 0;
        position: absolute;
        top: 50px;
        right: 30px;
    }
}

.products_container {
    padding: 0 ;
    border-bottom: 1px solid rgba(85, 85, 85, 0.4);
    .products_listing {
        display: flex;
        position: relative;
        padding: 20px 0;
        .product_image {
            width: 100%;
            max-width: 146px;
            height: 197px;
            padding: 0 0 0 20px;
            img {
                width: 100%;
                height: 100%;
                object-fit:contain;
            }
        }
        .product_details {
            .product_name {
            width: 100%;
            max-width: 300px;
            width: 100%;
            padding: 0 38px 28px;

                h3 {
                    font-size: 22px;
                    letter-spacing: 0px;
                    color: #39332E;
                    margin: 0px;
                    line-height: 24px;
                    font-family: "Helvetica Neue LT Std";
                    font-weight: 600;
                    word-break: break-all;

                }
            }
        }
        .delete_price {
            .deleteIcon {

            }
            .price {
                letter-spacing: 0.3px;
                color: #39332E;
                font-size: 30px;
                font-weight: 600;
                font-family: 'Helvetica Neue LT Std';
                position: absolute;
                bottom: 26px
              
            }
        }
    }
    .deleteIcon img {
    max-width: 30px;
}
    .shipping_dropdown {
        width: 100%;
        display: flex;
        /* margin-bottom: 80px; */
        border-bottom: 1px solid rgba(85, 85, 85, 0.4);
        padding-bottom: 15px;

        .MuiFormControl-root {
            margin: 10px 10px;
            max-width:100%;
            width: 100%;
        }
    }
}
.shipping_dropdown > div {
    width: 100%;
    margin: 20px;
}
.quality-box{
    margin-left: 56px;
}
input#quantity {
    /* margin-left: 56px; */
    background-color: transparent !important;
    margin-top: 0%;
}
.footer_section_mincart .field_button {
    padding: 0 0 26px;
    display: flex;
}
.footer_section_mincart input {
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    max-width: 100%;
    width: 100%;
    padding: 0 30px;
    height: 60px;
    font-size: 20px;
    color: #39332E;
}
.footer_section_mincart .textField {
    max-width: 408px;
    width: 100%;
}
.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}
.footer_section_mincart {
    padding: 20px;
}
.footer_section_mincart .field_button button {
    max-width: 88px;
    height: 39px;
    width: 100%;
    font-size: 18px;
    margin: 12px 20px;
}
.subtotal_section p {
    color: #39332E;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Helvetica Neue LT Std';
    margin: 10px 0  50px;
}
.total p {
    color: #39332E;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Helvetica Neue LT Std';
    margin: 10px 30px 50px 0;
}
.total {
    display: flex;
    justify-content: space-between;
    padding: 40px 0 0;
    width: 100%;
}
.subtotal_section {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.calculation_section .button_css.undefined {
    margin: 0;
    float: right;
}
hr.MuiDivider-root.MuiDivider-fullWidth.css-9mgopn-MuiDivider-root {
    border: none;
}
.deleteIcon {
    position: absolute;
    right:18px;
}
.products_container .products_listing .product_details .product_name h3 {
    word-break: break-word;
}
/* .delete_price {
    position: relative;
} */
.product_details {
    max-width: 354px;
    width: 100%;
}
.side-cart-cross {
    position: absolute;
    right: 24px;
    top: 10px;
}
.side-cart-cross img {
    max-width: 28px;
    width: 100%;
}
.side-cart-cross svg {
    color: #fff !important;
    opacity: 1;
    width: 12px;
    height: 12px;
}
.flayout_cart {
    max-width: 662px !important;
    width: 100% !important;
    @media(max-width:767px){
        max-width:550px !important;
    }
}
.quality-box .input-group {
    max-width: 111px;
    padding: 0;
}
.input-group .btn {
    padding: 0 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.input-group-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}
.quality-box input {
    max-width: 53px;
    padding-left: 20px;
}
.products_container .products_listing .product_image {
    height: 170px;

}
.products_container .products_listing .delete_price .price {
    bottom:0;
    right:24px;
}

@media(max-width:1199px){
.products_container .shipping_dropdown {
    margin-bottom: 34px;
    padding-bottom: 0;
}
.products_container .products_listing .product_details .product_name {
    max-width: 100%;
    width: 100%;
    padding: 0px 10px 28px;
}
 .products_container .products_listing .product_image {
    height: 150px;
    object-fit: cover;
}
.quality-box {
    margin-left: 20px;
}
 .shipping_dropdown > div {
    width: 100%;
    margin: 20px;
}
.products_container .products_listing .delete_price .price {
    font-size: 23px;
}
.products_container .shipping_dropdown .MuiFormControl-root {
    max-width: 100%;
    width: 100%;
}
.deleteIcon {
    right: -140px;
}
.products_container .products_listing .delete_price .price {
    right: -133px;
}
 .subtotal_section p {
    font-size: 20px;
    margin: 10px 0 50px 0px;
}
.calculation_section .button_css.undefined {
    margin: 0px 30px 16px auto;
}
.heading_section h1 {
    font: bold 40px / 40px "Playfair Display";
}
}
@media(max-width:767px){
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.flayout_cart.MuiDrawer-paperAnchorRight {
    width: 100% !important;
    max-width: 545px !important;
}
.products_container .products_listing .delete_price .price {
    right: 15px;
}
 .deleteIcon {
    right: 15px;
}
.products_container .products_listing .product_details .product_name h3 {
    font-size: 15px;
}
.products_container .products_listing .product_image {
    height: auto;
    object-fit: cover;
}
.products_container .deleteIcon img {
    max-width: 21px;
}
.products_container .products_listing .delete_price .price {
    font-size: 20px;
}
 .products_container .products_listing .product_details .product_name {
    padding: 0px 10px 0;
}
 .products_container .products_listing .delete_price .price {
    bottom: 0;
}
.calculation_section button {
    font-size: 16px;
    max-width: 150px;
    height: 40px;
}
.calculation_section .button_css.undefined {
    margin: 0;
}
.heading_section h1 {
    font: bold 30px / 40px "Playfair Display";
    padding: 28px 0 0;
}
.products_container .products_listing .delete_price .price {
    font-size: 15px;
}
 .products_container .products_listing .product_details .product_name h3 {
    white-space: break-spaces;
}
 .products_container .products_listing .product_image {
    max-width: 96px;
}
.footer_section_mincart {
    padding: 20px;
}
.product_details {
    max-width: 310px;
}
.products_container .products_listing {

    justify-content: space-between;
}

}

@media(max-width:568px){
    .footer_section_mincart {
    padding: 15px;
}
.products_container .products_listing {
    flex-wrap: wrap;
    justify-content: center;
}
 .products_container .products_listing .product_image {
    max-width: 96px;
    width: 100%;
}

.delete_price {
    position: relative;
    max-width: 100%;
    flex: 0 0 100%;
    display: flex;
    flex-wrap: nowrap;
    padding: 19px 15px 0;
}
.deleteIcon {
    right: 0;
    position: relative;
}
.products_container .products_listing .product_details .product_name h3 {
    word-wrap: break-word;
}
.product_details {
    max-width: 100%;
    width: 100%;
    flex-wrap: wrap;
    padding: 10px 0;
    text-align: center;
}
.quality-box .input-group {
    margin: 20px auto;
}
.quality-box {
    margin-left: 0;

}
.footer_section_mincart {
    margin-bottom: 35px;
}
}

`;

export default MiniCartStyleCss;