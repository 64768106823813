import { createSlice } from "@reduxjs/toolkit";
import api from "../../AxiosHelper";
import _ from "lodash";
import { _signUpPayload, _storeCustomerTypes } from "../../Methods/commonPayload";
import { CacheHeader, MIDDLE_CREDENTIAL } from "../../Methods/normalMethods";
import * as Sentry from "@sentry/react";

const LoginSlice = createSlice({
    name: "loginSlice",
    initialState: {
        data: "",
        alluserData: [],
        errorMsg: "",
        signupData: "",
        loading: false,
        validMerchantProfile: null,
        myaccount: "",
        cashPoints: "",
        emailerror: "",
        validateCustomerToken: 'VALID',
    },
    reducers: {
        setLoading: (state, action) => {
            state['loading'] = action.payload;
        },
        setLoginData: (state, action) => {
            state['data'] = action.payload?.data?.customerId;
            localStorage.setItem('Token', action.payload?.data?.customerId);
        },
        setSignUpData: (state, action) => {
            state['signupData'] = action.payload?.data
            localStorage.setItem('Token', action.payload?.data);
        },
        setAllUserData: (state, action) => {
            state['alluserData'] = action?.payload?.data;
        },
        setCreateMerchantProfile: (state, action) => {
            state['validMerchantProfile'] = action?.payload?.data
        },
        setCashPoints: (state, action) => {
            state['cashPoints'] = action?.payload?.data
        },
        setError: (state, action) => {
            state['errorMsg'] = action.payload;
        },
        setValidateCustomerToken: (state, action) => {
            state['validateCustomerToken'] = action?.payload;
        },
        setMyaccount: (state, action) => {
            state['myaccount'] = action?.payload?.data?.customerId;
        },
        setEmailError: (state, action) => {
            state['emailerror'] = action.payload;
        },
    },
});

export const {
    setLoading, setLoginData, setSignUpData, setAllUserData, setMyaccount, setCreateMerchantProfile, setCashPoints, setEmailError, setError, setValidateCustomerToken
} = LoginSlice.actions;

const ErrorMsg = (errorResult) => dispatch => {
    if (errorResult?.length > 0) {
        _.map(errorResult, ({ errorMessage }) => {
            if (errorMessage) {
                dispatch(setError(errorMessage));
                setTimeout(() => dispatch(setError("")), 4000);
                return;
            }
        });
    }
}

// ************************** login api **************************//

export const LoginApi = ({ email, password }, callback) => dispatch => {
    const header = { CustomerUsername: email, CustomerPassword: password };
    dispatch(setLoading(true));
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        endpoint: '/Crm/Customers/Authenticate',
        method: "login",
        ...header
    }).success((res) => {
        dispatch(setLoading(false));
        if (res?.data) {
            Sentry.setUser({ id: res?.data?.customerId, email: res?.data?.email });
            dispatch(setLoginData(res));
            if (callback) {
                callback(res?.data);
            }
        }
    }).error((err) => {
        dispatch(setEmailError(err?.errorMessage));
        dispatch(setLoading(false));
    }).send();
}

export const getAllCustomerData = (callback, id = null, email = "") => dispatch => {

    const URL = id ? `/Crm/Customers/${id}` : email ? `/Crm/Customers/?userName=eq:${email}` : '/Crm/Customers/';

    dispatch(setLoading(true));
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: "GET",
        endpoint: URL,
        ...CacheHeader,
    }).success((res) => {
        dispatch(setLoading(false));
        if (res?.data) {
            dispatch(setAllUserData(res));

            if (callback) {
                callback(res?.data);
            }
        }
    }).error((err) => {
        dispatch(ErrorMsg(err?.errorResults));
        dispatch(setLoading(false));
    }).send();
}
//************************** BO API*************************************** */
export const MyAccoutnAPI = (id = null, customerId) => dispatch => {

    const CUSTOMER_ID = customerId ? customerId : localStorage.getItem('Token');
    dispatch(setLoading(true));
    const URL_PATH = id === null && `/Authorize/${CUSTOMER_ID}/RetrieveSSOKey`
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: "GET",
        endpoint: URL_PATH,
    }).success((res) => {
        dispatch(setLoading(false));
        dispatch(setMyaccount(res));
        if (res?.data) {
            window.location.href = `${process.env.REACT_APP_ABO_URL}?sso=${res?.data}`;
        }

    }).error((err) => {
        dispatch(ErrorMsg(err?.errorResults));
        dispatch(setLoading(false));
    }).send();
}

// ************************* create merchantProfile ******************************
export const createMerchantProfile = (CustomerId, data, callback) => dispatch => {

    dispatch(setLoading(true));
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: "POST",
        endpoint: `/Money/CustomerAccount/${CustomerId}/${1}/CreateMerchantProfile`,
        ...CacheHeader,
        data: JSON.stringify({
            firstName: data?.firstName,
            lastName: data?.lastName,
            address1: data?.address,
            address2: data?.address_2,
            city: data?.city,
            state: data?.state,
            zip: data?.zipcode,
            country: data?.country,
            email: data?.email,
            phone: data?.phone.replace(/\D/g, ''),
        })
    }).success((res) => {
        // dispatch(setLoading(false));
        dispatch(setCreateMerchantProfile(res));
        if (process.env.REACT_APP_OTP_ENABLE === 'true') {
            dispatch(ValidateCustomerProfile(CustomerId, (data) => callback(data)))
        } else {
            callback('VALID');
            dispatch(setLoading(false));
        }
    }).error((err) => {
        dispatch(ErrorMsg(err?.errorResults));
        dispatch(setLoading(false));
    }).send();
}

// ***************** Customer Otp **************************//
export const ValidateCustomerProfile = (CustomerId, callback) => dispatch => {
    if (process.env.REACT_APP_OTP_ENABLE === 'true') {
        dispatch(setLoading(true));
        api().setHeaders(MIDDLE_CREDENTIAL).post().data({
            method: "GET",
            endpoint: `/Money/CustomerAccount/${CustomerId}/ValidateCustomerProfile`,
        }).success((res) => {
            dispatch(setLoading(false));
            dispatch(setValidateCustomerToken(res?.data))
            if (callback) {
                callback(res?.data);
            }
        }).error((err) => {
            dispatch(ErrorMsg(err?.errorResults));
            dispatch(setLoading(false));
        }).badRequest400((data) => {
            callback(data?.data);
        }).send()
    }
}

// ************** OTP varification Check *****************//

export const AuthenticateProfileCode = (CustomerId, validatekey, code, callback) => dispatch => {
    dispatch(setLoading(true));
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: 'GET',
        endpoint: `/Money/CustomerAccount/${CustomerId}/AuthenticateProfileCode?validationKey=${validatekey}&code=${code}`
    }).success((res) => {
        dispatch(setLoading(false));
        if (callback) {
            callback(res?.data);
        }
    }).error((err) => {
        dispatch(setError(err?.errorMessage));
        setTimeout(() => dispatch(setError("")), 4000);
        dispatch(setLoading(false));
    }).send();
}

// // ********************* Point Accounts *************************** //

export const PointAccounts = (token, CustomerId) => dispatch => {
    const header = { Authorization: `Bearer ${token}` };
    dispatch(setLoading(true));
    api().setHeaders(header).get(`/Customers/${CustomerId}/${1}/PointAccount`).success((res) => {
        dispatch(setLoading(false));
        dispatch(setCashPoints(res));
    }).error((err) => {
        // dispatch(ErrorMsg(err?.errorResults));
        dispatch(setLoading(false));
    }).send();
}

// ********************** SignUp api ******************************** //
export const SignUpFormApi = (data, section, callback) => dispatch => {
    const formData = (section === "wholesale") ? _signUpPayload(data, 3, section) : _signUpPayload(data, 1, section);
    dispatch(setLoading(true));
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        method: 'POST',
        endpoint: '/Crm/Customers',
        data: JSON.stringify(formData),
        ...CacheHeader
    }).success((res) => {
        if (res?.data) {
            if (section !== "leadUser") {
                dispatch(setSignUpData(res));
                localStorage.setItem('customerTypeDescr', JSON.stringify({ type: 1, customerType: 'Lead', priceType: 1 }), { expires: 60 });
                if (process.env.REACT_APP_OTP_ENABLE === "true") {
                    dispatch(createMerchantProfile(res?.data, data, (data) => callback(data)))
                } else {
                    dispatch(setLoading(false));
                    callback(null)
                }
                // callback(res?.data);
                // dispatch(setLoading(false));
            } else {
                callback(res?.data);
                dispatch(setLoading(false));
            }
        }
    }).error((err) => {
        dispatch(ErrorMsg(err?.errorResults));
        dispatch(setLoading(false));
    }).send();
}



// **************** Update Customer detail **********************
export const customerUpdateApi = (data, section, customerId, callback) => dispatch => {
    let CUSTOMER_TYPE_CHANGE = 1;
    let CUSTOMER_TYPE = localStorage?.getItem('customerTypeDescr') ? JSON.parse(localStorage?.getItem('customerTypeDescr')) : null;

    switch (section) {
        case 'retailer':
            CUSTOMER_TYPE_CHANGE = CUSTOMER_TYPE?.type === 1 ? 2 : CUSTOMER_TYPE?.type;
            break;
        case 'enrollmentCheckout':
            CUSTOMER_TYPE_CHANGE = 4;
            break;
        case 'wholesale':
            CUSTOMER_TYPE_CHANGE = 3;
            break
        default:
            CUSTOMER_TYPE_CHANGE = CUSTOMER_TYPE?.type || 1;
            break
    }

    const formData = (section === 'addressChangeCheckout') ? data : _signUpPayload(data, CUSTOMER_TYPE_CHANGE, section, 'updated');

    dispatch(setLoading(true));
    api().setHeaders(MIDDLE_CREDENTIAL).post().data({
        endpoint: `/Crm/Customers/${customerId}`,
        method: "PUT",
        ...CacheHeader,
        data: JSON.stringify(formData)
    }).success((res) => {
        if (["enrollmentCheckout", 'retailer', 'loyaltyCheckout', 'addressChangeCheckout']?.includes(section)) {
            dispatch(setLoading(false));
            if (res?.data) {
                _storeCustomerTypes(dispatch, customerId, null, callback(res?.data))
                // localStorage.setItem('customerTypeDescr', JSON.stringify({ type: CUSTOMER_TYPE_CHANGE, customerType: data?.customerTypeDescr, priceType: 3 }), { expires: 60 });
            }
        } else {
            if (process.env.REACT_APP_OTP_ENABLE === "true") {
                dispatch(createMerchantProfile(res?.data, data, (data) => callback(data)))
            } else {
                dispatch(setLoading(false));
                callback(null)
            }
            // callback(res?.data);
            // dispatch(setLoading(false));
        }
    }).error((err) => {
        dispatch(ErrorMsg(err?.errorResults));
        dispatch(setLoading(false));
    }).send();

}


export default LoginSlice.reducer;