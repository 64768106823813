import styled from "styled-components";

export const FooterStyleCss = styled.div`

/* copy-right */
    .copy-right {
        background: #555;
        text-align: center;
        padding: 22px 0 8px;
    }
    .copy-right h6 {
        font-size: 18px;
        color: #fff;
        font-family: 'Helvetica';
    }
    .logo1 img {
        max-width: 122px;
        width: 100%;
        opacity: 0.8;
        @media(max-width:568px){
            max-width: 70px;
        }
    }
    .footer ul li {
        list-style: none;
    }
    .logo-2 img {
        max-width: 112px;
        width: 100%;
        @media(max-width:568px){
            max-width: 70px;
        }
    }
    .logo-2 ul li {
        padding: 0 40px 0 0;
    }
    .logo-2 ul {
        display: flex;
    }
    h4 , h6{
    font-family:Helvetica Neue LT Std;
    }
    .footer ul li a {
        color: #6C6C6C;
        font-size: 16px;
        line-height: 34px;
        font-weight: 400;
    }
    .footer-logo h6 {
        color: rgb(108, 108, 108);
        font-size: 15px;
        line-height: 25px;
        max-width: 387px;
        width: 100%;
        padding: 10px 0 0;
        @media(max-width:767px){
            max-width:100%;
        }
        @media(max-width:568px){
            font-size: 16px;
            line-height: 22px;
        }
    }
    .privacy-policy-nav ul:nth-child(3) img {
        max-width: 24px;
        width: 100%;
        object-fit: cover;
    }
    .privacy-policy-nav ul:nth-child(3) ,.privacy-policy-nav ul:nth-child(4) {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        padding: 50px 0 0;
        @media(max-width:767px){
            padding:0;
        }
    }
    .privacy-policy-nav ul:nth-child(3) li {
        padding: 0 15px 0 0;
    }
    .privacy-policy-nav ul:nth-child(4) img {
        max-width: 55px;
        width: 100%;
        object-fit: cover;
        margin-bottom: 16px;
    }
    ul.credit-card1 {
        margin-bottom: 0;
    }
    .privacy-policy-nav ul:nth-child(4) img {
        max-width: 48px;
        width: 100%;
        object-fit: cover;
        margin-bottom: 16px;
        margin-right: 7px;
    }
    .logo-2 h4 {
        padding: 34px 0 11px;
    }
    
    ul.credit-card2 li img {
        max-width: 79px;
        width: 100%;
        object-fit: cover;
        margin-bottom: 16px;
        margin-right: 7px;
    }
    ul.credit-card2 li:first-child img {
        max-width: 52px;
    }
    ul.credit-card2 {
        display: flex;
        margin-bottom: 0;
    }
    h2 {
        font-family: 'Playfair Display', serif;
        font-size: 60px;
        font-weight:bold;
        color: #39332E;
    }
    h3 {
        font-size: 24px;
        color: #555555;
        line-height: 36px;
    }
    .footer {
        padding: 26px 0;
        background: #F4F0EB33;
    }
    .footer h4 {
        font-size: 16px;
        font-weight: bold;
        color: rgb(57, 51, 46);
        line-height: 24px;
        padding: 50px 0px 6px;
        text-transform: uppercase;
        @media(max-width:991px){
            font-size:20px;
        }
        @media(max-width:767px){
            padding:0;
        }
        @media(max-width:568px){
            font-size:20px;
        }
    }
    .footer ul li a:hover {
        color: #2a533f;
        font-weight: 600;
    }
    .footer .logo-2 h4 {
    padding-top: 24px;
    @media(max-width:568px){
            padding-top:9px;
        }
}

`;