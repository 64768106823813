import { CircularProgress } from "@mui/material";
import moment from "moment";
import localStorageCall from "./localstorage.hook";
// import { CUSTOMER_TYPE } from "./normalMethods";

export function _priceTypeForCalculate(oneTimeProducts = []) {
    let priceType = 1;
    const CUSTOMER_TYPE = localStorage.getItem('customerTypeDescr') ? JSON.parse(localStorage.getItem('customerTypeDescr')) : { priceType: 1, customerType: 'Customer' };
    // if (section === 'distributor') {
    // priceType = (autoshipProducts?.length > 0) ? 4 : 1;
    // } else {
    if (CUSTOMER_TYPE?.priceType && CUSTOMER_TYPE?.priceType !== 1) {
        priceType = CUSTOMER_TYPE?.priceType;
    } else {
        priceType = (oneTimeProducts?.length > 0) ? 2 : 1;
    }
    // }

    return priceType;
}

export const calculateDataUserPayload = (userData, selectedCheck, byPass, oneTimeProducts) => {
    console.log("byPassbyPassbyPass", byPass)
    const CUSTOMER_TYPE = localStorage.getItem('customerTypeDescr') ? JSON.parse(localStorage.getItem('customerTypeDescr')) : { priceType: 1, customerType: 'Customer' };
    return ({
        "preAuthOrderId": 0,
        "companyId": 320,
        "customerId": localStorageCall().getSingleItem('Token'),
        "field1": "",
        "field2": "",
        "field3": "",
        "field4": "",
        "field5": "",
        "field6": "",
        "field7": "",
        "field8": "",
        "field9": "",
        "field10": "",
        "orderType": 2,
        "subType": 0,
        "orderStatusType": 1,
        "country": userData?.shipping_country,
        "county": userData?.shipping_country,
        "zip": userData?.shipping_zipcode,
        "state": userData?.shipping_state,
        "storefrontId": 1,
        "priceType": (CUSTOMER_TYPE?.priceType && CUSTOMER_TYPE?.priceType !== 1) ? CUSTOMER_TYPE?.priceType : (oneTimeProducts?.length > 0) ? 2 : 1,
        "orderStatusSubType": 1,
        "currencyCode": "USD",
        "shipMethodType": selectedCheck?.shipCarrierId || 0,
        "shipCarrierId": selectedCheck?.shipMethodType || 0,
        "userName": userData?.shipping_email,
        "firstName": userData?.shipping_urname,
        "lastName": userData?.shipping_lastname,
        "address1": userData?.shipping_street,
        "address2": userData?.shipping_street2,
        "city": userData?.shipping_city,
        "phone": userData?.shipping_phone,
        "email": userData?.shipping_email,
        "bypassAddressValidation": byPass === false ? true : false,
        "orderDate": moment.utc().format(),
        "returnAllShipMethods": true,
        "taxExemptOverride": false,
        "couponCodes": [""],
        "shipMethod": {
            "shipCarrierId": selectedCheck?.shipCarrierId || 0,
            "shipMethodType": selectedCheck?.shipMethodType || 0,
            "param1": "",
            "param2": "",
            "param3": "",
            "param4": "",
            "param5": ""
        },

        "payments": [],
        "sessionId": "",
    });
}

export const calculateReouccringPayload = (userData, selectedCheck, byPass) => {
    console.log("byPass", byPass)
    const CUSTOMER_TYPE = localStorage.getItem('customerTypeDescr') ? JSON.parse(localStorage.getItem('customerTypeDescr')) : { priceType: 1, customerType: 'Customer' };
    return ({
        customerId: localStorageCall().getSingleItem('Token'),
        recurringOrderType: 1,
        recurringOrderStatusType: 1,
        frequencyType: 1,
        priceType: CUSTOMER_TYPE?.priceType > 1 ? CUSTOMER_TYPE?.priceType : 2,
        startDate: moment.utc().format(),
        endDate: moment().year(1929).utc().format(),
        storeFrontId: 1,
        currencyCode: 'USD',
        shipCarrierId: selectedCheck?.shipCarrierId || 0,
        shipMethodType: selectedCheck?.shipMethodType || 0,
        paymentMethodId: 0,
        orderType: 1,
        orderStatusType: 2,
        company: "",
        firstName: userData?.shipping_urname,
        lastName: userData?.shipping_lastname,
        priceCountryCode: userData?.shipping_country,
        shipToAddressType: 0,
        bypassAddressValidation: byPass === false ? true : false,
        address1: userData?.shipping_street,
        address2: userData?.shipping_street2,
        city: userData?.shipping_city,
        state: userData?.shipping_state,
        zip: userData?.shipping_zipcode,
        country: userData?.shipping_country,
        phone: userData?.shipping_phone,
        email: userData?.shipping_email,
        "shipMethod": {
            "shipCarrierId": selectedCheck?.shipCarrierId || 0,
            "shipMethodType": selectedCheck?.shipMethodType || 0,
            "param1": "",
            "param2": "",
            "param3": "",
            "param4": "",
            "param5": ""
        },
        field1: "",
        field2: "",
        field3: "",
        field4: "",
        field5: "",
        field6: "",
        field7: "",
        field8: "",
        field9: "",
        field10: "",
        payments: [],
    });
}

export const _paymentPayload = (data, values, section = "normal", selectedPaymentOption, selectedCard) => {
    let payload = {
        "token": data?.id || null,
        "tokenType": 3,
        "phone": values?.shipping_phone || "",
        "email": values?.shipping_email || "",
        "currencyCode": "USD",
        "pointAccountType": 0,
        "country": values?.shipping_country || "",
        "state": values?.shipping_state || "",
        "city": values?.shipping_city || "",
        "address2": values?.shipping_street2 || "",
        "address1": values?.shipping_street || "",
        "lastName": values?.shipping_lastname || "",
        "firstName": values?.shipping_urname || "",
        "paymentType": 1,
        "zip": values?.shipping_zipcode || "",
        "saveToken": section === 'normal' ? false : true,
        "paymentOptionId": selectedPaymentOption || selectedCard?.accountType || 1,
    };
    return section !== "normal" ? { ...payload, recurringPaymentActionType: 1 } : payload;
}

export const OuterLoader = ({ section }) => (
    <div className='order-summary' style={{ padding: "0" }}>
        <h2>{section} Order Summary</h2>
        <div className='order-data loader_order'>
            <CircularProgress />
        </div>
        <p style={{ color: "red" }}>Please complete your shipping information</p>
    </div>
);

export const CreateObject = (row) => ({
    id: row?.itemId,
    productName: row?.description,
    image: row?.field1,
    price: row?.itemPrice,
    quantity: row?.quantity,
    itemCode: row?.fullItemCode || row?.itemCode,
});


export function _checkoutAddressFormat(data, userData, useDefaultAddressOrNot, countryState) {
    return ({
        shipping_urname: userData?.shipping_urname || data?.firstName,
        shipping_lastname: userData?.shipping_lastname || data?.lastName,
        shipping_email: userData?.shipping_email || data?.emailAddress,
        shipping_phone: userData?.shipping_phone || data?.phoneNumbers?.cellPhone,
        shipping_street: useDefaultAddressOrNot?.address_1 || userData?.shipping_street || data?.shippingAddress?.address1,
        shipping_street2: userData?.shipping_street2 || userData?.shipping_street2 || data?.shippingAddress?.address2,
        shipping_city: useDefaultAddressOrNot?.city || userData?.shipping_city || data?.shippingAddress?.city,
        shipping_zipcode: useDefaultAddressOrNot?.postal_code || userData?.shipping_zipcode || data?.shippingAddress?.postalCode,
        shipping_country: useDefaultAddressOrNot?.country || countryState?.shipping_country || data?.countryCode,
        shipping_state: useDefaultAddressOrNot?.state_region || countryState?.shipping_state || data?.shippingAddress?.regionProvState
    })
}