import React from 'react';
import { SingleProductStyle } from './SingleProduct.style';
import { Rating } from '@mui/material';
import _, { reverse } from 'lodash';
import ClassNames from 'classnames';
import AccordianComponent from '../../../inputs/AccordianComponent';
import SwiperSlideComp from '../../../Components/SwiperSlideComp';
import Htmlparse from 'html-react-parser';
import { FloatValues, _productPrice, storeAssigned } from '../../../services/Methods/normalMethods';
import LoaderComponent from '../../../Layout/components/Footer/components/Loader';
import parse from 'html-react-parser';
import { CustomerReview } from './CustomerReviews';

const MainSingleProduct = (props) => {

    const { selectedPrice, setSelectedPrice, singleProductsDetail, name, id, qty, _IncreaseDecreaseFunction, _addToCart, isProductLoading } = props;
    const Unique_SECTION = [
        { image: require('../assets/madeinusa.png'), para: "Our premium quality products are manufactured in Utah, USA." },
        { image: require('../assets/antimicrobial.png'), para: "Antimicrobial Formulation" },
        { image: require('../assets/fdaRegisterd.png'), para: "Utah Dept of Agriculture and Food Registered" },
        { image: require('../assets/AwardWining.png'), para: "Most innovated beauty product featured in Elle Magazine." },
        { image: require('../assets/quality.png'), para: "Every customer is a member of our health warrior team." },
        { image: require('../assets/settings.png'), para: "Every customer is a member of our health warrior team." }
    ];
    const description = ``;

    const AccordianSection = [
        // { title: "Description", content: singleProductsDetail?.otherFields?.longField1 || description, active: true, for: "desktop_view" },
        // { title: "Additional information", content: singleProductsDetail?.otherFields?.longField2 || description, active: false, for: "desktop_view" },
        // { title: "Benefits", content: product?.products?.benefits, active: false, for: "mobile_view" },
        { title: "Ingredients", display: !!singleProductsDetail?.otherFields?.longField2, content: singleProductsDetail?.otherFields?.longField2 || description, active: false, for: "" },
        // { title: "Administration", content: singleProductsDetail?.otherFields?.longField4 || description, active: false, for: "" },
        { title: "COA", display: !!singleProductsDetail?.otherFields?.longField3, content: singleProductsDetail?.otherFields?.longField3 || description, active: false, for: "" },
    ];
    let VALUES = [];

    _.map([singleProductsDetail?.mediumImage, singleProductsDetail?.largeImage], (items) => {
        VALUES.push({ image: <img src={items} alt="product image" /> });
    })
    const AMOUNT_PRICE = _productPrice(singleProductsDetail?.itemPricing, 'oneTime', singleProductsDetail?.isEligibleForAutoOrder);
    const AUTOSHIP_PRICE = _productPrice(singleProductsDetail?.itemPricing, 'autoship', singleProductsDetail?.isEligibleForAutoOrder);


    const filteredReviews = _.map(_.find(CustomerReview, review => review.id === +id)?.review, (row, index) => ({ id: index + 1, ...row })) || [];
    return (
        <SingleProductStyle>
            {_.isEmpty(singleProductsDetail) && <LoaderComponent />}
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-text">
                                <ul className="breadcrumb">
                                    <li><a href={`/${storeAssigned()}`}>Home</a></li>
                                    <li><a href={`/${storeAssigned()}/shop/products`}>Products</a></li>
                                    <li className="active">
                                        <a href="#" style={{ textTransform: "capitalize" }}>{singleProductsDetail?.title}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="single-product-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="left-side-text">
                                <h2>{singleProductsDetail?.title}</h2>
                                <div className="price-section">
                                    <h3>${FloatValues(AMOUNT_PRICE)}
                                        {/* <span className="availabe-text"> — available on subscription</span> */}
                                    </h3>
                                </div>
                                <div className="review-section">
                                    <Rating value={5} readOnly />
                                    <span className="text-review-text">{`(${filteredReviews.length} reviews)`}</span>
                                </div>
                                {singleProductsDetail?.otherFields?.longField1 && parse(singleProductsDetail?.otherFields?.longField1)}
                                {(singleProductsDetail?.fullItemCode === '1049US') &&
                                    <div className="learn-more-section">
                                        <a href='http://www.insuless.us/' target="_blank" >Learn more</a>
                                    </div>
                                }
                                <div className="accordian-section">
                                    <div id="accordion">
                                        {_.map(AccordianSection, ({ title, display, content, active }) => (
                                            <AccordianComponent key={title} {...{ title, display, content: Htmlparse(content), active }} />
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-5">
                            <div className="right-side-image">

                                <SwiperSlideComp arrayData={VALUES} />
                                <div className="product-details-now">
                                    <h5>{singleProductsDetail?.title?.replace(/Abeytu´&reg;® Naturals/g, "")}</h5>
                                    {singleProductsDetail?.isEligibleForAutoOrder ?
                                        <>
                                            <h6>Delivery Frequency:</h6>
                                            <div className="most-popular">
                                                <h6>Most Popular</h6>
                                            </div>
                                            <div className="box-quality">
                                                {_.map([
                                                    { title: "One time", section: "oneTime", price: AMOUNT_PRICE, classes: "oneTime cursor_style" },
                                                    { title: <><span>every</span>30 DAYS</>, section: "30Days", price: AUTOSHIP_PRICE, classes: "everyTime cursor_style" },
                                                    { title: <><span>every</span>60 DAYS</>, section: "60Days", price: AUTOSHIP_PRICE, classes: "everyTime cursor_style" },
                                                ], ({ title, section, price, classes }) => (
                                                    <div key={section} className={ClassNames(classes, section, { 'selected': (selectedPrice === section) })} onClick={() => setSelectedPrice(section)}>
                                                        <h6>{title}</h6>
                                                        <span className="price-box">${price}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                        :
                                        <h3 style={{ color: "#000" }}> One time</h3>
                                    }
                                    <div className="quality-box">
                                        <div className="input-group">
                                            <span className="input-group-btn">
                                                <button type="button" className="quantity-left-minus btn btn-danger btn-number" onClick={() => _IncreaseDecreaseFunction('sub')}>
                                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                                </button>
                                            </span>
                                            <input type="text" id="quantity" name="quantity" className="form-control input-number" value={qty} min="1" max="100" onChange={() => console.log('qty')} />
                                            <span className="input-group-btn">
                                                <button type="button" className="quantity-right-plus btn btn-success btn-number" onClick={() => _IncreaseDecreaseFunction('add')}>
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                            </span>
                                        </div>
                                        <div className="add-bag-btn">
                                            <a className="btn btn-add-bag" onClick={() => _addToCart()}>Add to bag - ${FloatValues((selectedPrice === "oneTime") ? (AMOUNT_PRICE * qty) : (AUTOSHIP_PRICE * qty))}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="unique-logo single-unique-logo">
                {_.map(Unique_SECTION, ({ image, para }, index) => (
                    <div className="logo-unique" key={"unique" + index}>
                        <div className="logo1">
                            <img src={image} />
                        </div>
                        <p>{para}</p>
                    </div>
                ))}
            </div>
            <div className="client-review1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="client-review-1">
                                <h2>Customer Reviews</h2>
                                <div className="review-section-now">
                                    <Rating value={5} readOnly />
                                    <span className="text-review-text">{`(${filteredReviews.length} reviews)`}</span>
                                </div>
                                <div className="out-five-part">
                                    <h5><span className="out-text">5</span> out of 5</h5>
                                </div>
                                <div className="sort-by-part">
                                    {/* <div className="sort-part-button">
                                        <a href="" className="btn btn-review">Write a review</a>
                                    </div> */}
                                </div>
                            </div>
                            {_.map(_.orderBy(filteredReviews, ['id'], 'desc'), (row, index) => (
                                <div className="main-comment-part" key={index}>
                                    <div className="client-view">
                                        <div className="profile-pic-client">j</div>
                                        <div className="profile-pic-comment">
                                            <div className="rating-comment">
                                                <Rating value={+row?.rating} readOnly /> {/* Assuming Rating is the component for displaying the rating */}
                                                <span className="date">{row?.date}</span>
                                            </div>
                                            <h3>{row.name}</h3>
                                        </div>
                                        {(row?.verifiedUser === "ok") && (
                                            <div className="profile-pic-icon">
                                                <h6>
                                                    <span className="circle-tick">
                                                        <i className="fa fa-check" aria-hidden="true"></i>
                                                    </span>
                                                    Verified purchaser
                                                </h6>
                                            </div>
                                        )}
                                    </div>
                                    <div className="comment-part">
                                        <h4>
                                            <strong>{row.title}</strong>
                                            <br />{row.comment}
                                        </h4>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>

        </SingleProductStyle >
    )
}

export default MainSingleProduct;