import React, { useEffect, useMemo, useState } from 'react';
import TextFieldComponent from '../../../inputs/TextFieldComponent';
import _ from 'lodash';
import styled from 'styled-components';

const PAYMENT_STYLE = styled.div`
& 
#collect_form_pay {
    min-height:${props => props?.paymentFormHtml ? props?.minHeight : "0px"};
    margin-top: 26px;
    @media only screen and (min-width:1440px) and (max-width: 1660px){
        min-height: 540px !important;
    }
    @media only screen and (min-width:1200px) and (max-width: 1439px){
        min-height: 770px !important;
    }
    @media only screen and (min-width:595px) and (max-width: 787px) {
        min-height: 540px;    
    }

    @media only screen and (min-width:360px) and (max-width: 595px) {
        min-height: 770px;    
    }

}
`;

const PaymentSection = (props) => {

    const { paymentFormHtml, savedThisCard, setSavedThisCard, minHeight } = props;

    return (
        <PAYMENT_STYLE {...{ paymentFormHtml, minHeight }}  >
            <iframe id="collect_form_pay" src={paymentFormHtml} style={{ width: '100%', height: '100%' }}></iframe>
            <div className="mb-3 field-class shipping condition-checkout" style={{
                marginTop: "0px"
            }}>
                <input type="checkbox"
                    checked={savedThisCard?.checked}
                    onChange={(e) => {
                        setSavedThisCard({ ...savedThisCard, checked: e.target.checked });
                    }}
                    disabled={savedThisCard?.readOnly}
                    id="save_this_card"
                    name="savedThisCard"

                /><p style={{ fontSize: "24px" }}>Save My Card</p>
            </div>

        </PAYMENT_STYLE>
    );
}

export default PaymentSection;