import React from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import TextFieldComponent from '../../../inputs/TextFieldComponent';
import LoaderComponent from '../../../Layout/components/Footer/components/Loader';
import _ from 'lodash';

const AddressSection = (props) => {

    const { section, disabled, FieldMethod, setFieldValue, handleBlur,
        handleChangeFunction, normalAddressError, errors, handleChange,
        values, countryState, setCountryState, valuesShip, countryCodes
    } = props;


    function _onChangeField(event, values) {
        if (section === "shipping") {
            handleBlur(event);
            handleChangeFunction(event, values);
        }
    }
    return (
        <>
            {valuesShip?.isLoading && <LoaderComponent />}
            <div className='mb-2 field-class'>
                <TextFieldComponent
                    required={false}
                    label="First name"
                    disabled={disabled}
                    name={section + "_urname"}
                    value={values[`${section + "_urname"}`]}
                    onChange={handleChange}
                    onBlur={(event) => {
                        _onChangeField(event, values)
                    }}
                    error={!!errors[`${section}_urname`]}
                    errorMessage={errors[`${section}_urname`]}
                    className='form-control' />
            </div>

            <div className='mb-2 field-class'>
                <TextFieldComponent
                    required={false}
                    disabled={disabled}
                    label="Last name"
                    name={section + "_lastname"}
                    value={values[`${section + "_lastname"}`]}
                    onChange={handleChange}
                    onBlur={(event) => {
                        _onChangeField(event, values)
                    }}
                    error={!!errors[`${section}_lastname`]}
                    errorMessage={errors[`${section}_lastname`]}
                    className='form-control' />
            </div>

            {section === "billing" &&
                <div className='mb-2 field-class'>
                    <TextFieldComponent
                        type="email"
                        label="Email"
                        disabled={disabled}
                        name={section + "_email"}
                        value={values[`${section + "_email"}`]}
                        onChange={handleChange}
                        onBlur={(event) => {
                            _onChangeField(event, values)
                        }}
                        error={!!errors[`${section}_email`]}
                        errorMessage={errors[`${section}_email`]}
                        className='form-control' />
                </div>
            }
            <div className='row-form'>
                <div className='select hide-country' >
                    {countryCodes?.length > 0 &&
                        <CountryDropdown
                            value={countryState?.[`${section}_country`]}
                            priorityOptions={['US', 'CA']}
                            name={"country"}
                            valueType="short"
                            // whitelist={countryCodes}
                            whitelist={section === "shipping" ? _.filter(countryCodes, (row) => !(['CA']?.includes(row))) : countryCodes}
                            onChange={(val) => {
                                const event = { target: { name: `${section}_country`, value: val } }
                                if (!val) {
                                    setCountryState((prv) => ({ ...prv, [`${section}_country`]: val, [`${section}_countryError`]: "Please select the country" }));
                                }
                                else {
                                    setCountryState((prv) => ({ ...prv, [`${section}_country`]: val, [`${section}_countryError`]: "" }));
                                }
                                _onChangeField(event, values);
                            }}

                        />
                    }
                    {countryState?.[`${section}_countryError`] && <p className="error">{countryState?.[`${section}_countryError`]}</p>}
                </div>


                <div className="select">
                    <RegionDropdown
                        country={countryState?.[`${section}_country`] || "US"}
                        disabled={!countryState?.[`${section}_country`]}
                        value={countryState?.[`${section}_state`]}
                        name={'state'}
                        valueType="short"
                        defaultOptionLabel="State"
                        countryValueType="short"
                        onChange={(val, e) => {
                            const event = { target: { name: `${section}_state`, value: val } }
                            if (!val) {
                                setCountryState((prv) => ({ ...prv, [`${section}_state`]: val, [`${section}_stateError`]: "Please select the state" }));
                            }
                            else {
                                setCountryState((prv) => ({ ...prv, [`${section}_state`]: val, [`${section}_stateError`]: "" }));
                            }
                            _onChangeField(event, values);
                        }}
                    />
                    {countryState?.[`${section}_stateError`] && <p className="error">{countryState?.[`${section}_stateError`]}</p>}

                </div>
            </div>
            <div className='mb-2 field-class'>
                <TextFieldComponent
                    className='form-control'
                    name={section + "_street"}
                    value={values[`${section + "_street"}`]}
                    onChange={handleChange}
                    onBlur={(event) => {
                        _onChangeField(event, values)
                    }}
                    error={!!(normalAddressError) || !!errors[`${section}_street`]}
                    errorMessage={(normalAddressError && section === "shipping") ? normalAddressError : "" || errors[`${section}_street`]}
                    label='Street Address*' />
            </div>
            <div className='mb-2 field-class'>
                <TextFieldComponent type="text"
                    className='form-control'
                    required={false}
                    name={section + "_street2"}
                    value={values[`${section + "_street2"}`]}
                    onChange={handleChange}
                    onBlur={(event) => {
                        _onChangeField(event, values)
                    }}
                    error={!!errors[`${section}_street2`]}
                    errorMessage={errors[`${section}_street2`]}
                    label='Apartment, suite, unit, etc (optional)' />
            </div>
            <div className='mb-2 field-class'>
                <TextFieldComponent
                    className='form-control'
                    name={section + "_city"}
                    label="Town/City"
                    value={values[`${section + "_city"}`]}
                    onChange={handleChange}
                    onBlur={(event) => {
                        _onChangeField(event, values)
                    }}
                    error={!!errors[`${section}_city`]}
                    errorMessage={errors[`${section}_city`]}

                />
            </div>
            <div className='mb-2 field-class'>
                <TextFieldComponent
                    className='form-control'
                    name={section + "_zipcode"}
                    label='Zip Code'
                    value={values[`${section + "_zipcode"}`]}
                    onChange={handleChange}
                    onBlur={(event) => {
                        _onChangeField(event, values)
                    }}
                    error={!!errors[`${section}_zipcode`]}
                    errorMessage={errors[`${section}_zipcode`]} />
            </div>
            <div className='mb-2 field-class'>
                <TextFieldComponent
                    className='form-control'
                    required={false}
                    name={section + "_phone"}
                    label='Phone (optional)'
                    value={values[`${section + "_phone"}`]}
                    onChange={handleChange}
                    onBlur={(event) => {
                        _onChangeField(event, values)
                    }}
                    error={!!errors[`${section}_phone`]}
                    errorMessage={errors[`${section}_phone`]}
                />
                {(process.env.REACT_APP_OTP_ENABLE === 'true') && <span>Note: phone number will be used to validate a code *</span>}
            </div>
        </>

    )
}

export default AddressSection;