import React from 'react';
import withRouter from '../services/HOC/withRouterHOC';
import { Footer } from './components/Footer';
import Header from './components/Header';

const MainLayout = ({ children, token, cartValues, selectedStore, setSelectedStore, countyObjects, countryCodes, alluserData, webAliseFilterData, ...props }) => {
    return (
        <div className='main_layout_section'>
            <Header {...{ token, cartValues, alluserData, selectedStore, setSelectedStore, countyObjects, countryCodes, webAliseFilterData, ...props }} />
            {children}
            <Footer />
        </div>
    )
}

export default withRouter(MainLayout);